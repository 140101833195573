import defaultTheme from 'tailwindcss/defaultTheme';
import colors from 'tailwindcss/colors';
import typography from '@tailwindcss/typography';

/** @type {import('tailwindcss').Config} */
export default {
  content: [
    './resources/**/*.blade.php',
    './resources/**/*.ts',
    './resources/**/*.js',
    './resources/**/*.vue',
    './resources/ts/panel/safelist.txt',

    './node_modules/@returnless/focus-ui/**/*.{vue,js}'
  ],
  theme: {
    extend: {
      maxWidth: {
        '8xl': '90rem',
      },

      borderColor: {
        DEFAULT: colors.slate[300],
      },

      fontFamily: {
        sans: ['Inter Var', ...defaultTheme.fontFamily.sans],
      },

      colors: {
        brand: {
          50: '#f4f4fd',
          100: '#d5d5f6',
          200: '#aaaaed',
          300: '#8080e3',
          400: '#5555da',
          500: '#2b2bd1',
          600: '#2222a7',
          700: '#1a1a7d',
          800: '#111154',
          900: '#09092a',
        },

        returnless: {
          darkBlue: '#000092',
          blue: '#2B2BD1',
          gray: '#f1f4f9',
        },
      },
    },
  },
  plugins: [
    typography,
  ],
};
