<script lang="ts" setup>
import { computed } from 'vue';
import { XMarkIcon } from '@heroicons/vue/24/solid';
import { BadgeSize, BadgeFontSize, BadgeRounded } from './types';

const props = withDefaults(defineProps<{
  color?: string | null;
  size?: BadgeSize;
  fontSize?: BadgeFontSize;
  rounded?: BadgeRounded;
  closeable?: boolean;
  open?: boolean;
  clamp?: boolean;
}>(), {
  color: null,
  size: BadgeSize.Small,
  fontSize: BadgeFontSize.Default,
  rounded: BadgeRounded.Default,
  closeable: false,
  open: true,
  clamp: false,
});

const emit = defineEmits<{
  close: [];
}>();

const textColorClass = computed(() => {
  return `text-${props.color}-800`;
});

const classList = computed(() => {
  return [
    textColorClass.value,
    `bg-${props.color}-100`,
    `hover:bg-${props.color}-200`,
    { 'px-2 text-xs leading-5': props.size === BadgeSize.Small },
    { 'px-3 py-0.5 text-sm': props.size === BadgeSize.Large },
    { 'font-medium': props.fontSize === BadgeFontSize.Default },
    { 'font-semibold': props.fontSize === BadgeFontSize.SemiBold },
    { 'rounded-md': props.rounded === BadgeRounded.Default },
    { 'rounded-full': props.rounded === BadgeRounded.Full },
  ];
});

const closeBadge = () => {
  emit('close');
};
</script>

<template>
  <span
    v-if="open"
    class="inline-flex items-center text-center"
    :class="classList"
  >
    <span :class="{ 'line-clamp-1': clamp }">
      <slot />
    </span>

    <button
      v-if="closeable"
      type="button"
      class="flex-shrink-0 ml-1 rounded-full inline-flex items-center justify-center hover:opacity-40"
      @click.stop="closeBadge"
    >
      <XMarkIcon
        class="h-3 w-3"
        :class="textColorClass"
      />
    </button>
  </span>
</template>
